<template>
	<div id="buyAddress">
		<div class="el-content" style="text-align: center;">
			<img class="img" src="@/assets/img/qrcode.png" alt="">
			<div>扫描二维码进货</div>
		</div>
	</div>

</template>

<script>
	export default{
		data(){
			return{
				
			}
		},
		mounted(){
			
		},
		methods:{
			
		}
	}
</script>

<style>
	.img{
		width: 200px;
		height: 200px;
	}
</style>
